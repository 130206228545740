import { render, staticRenderFns } from "./LandingFaq.vue?vue&type=template&id=fe3e7df6&scoped=true&"
import script from "./LandingFaq.vue?vue&type=script&lang=ts&"
export * from "./LandingFaq.vue?vue&type=script&lang=ts&"
import style0 from "./LandingFaq.vue?vue&type=style&index=0&id=fe3e7df6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe3e7df6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingPlaceholder: require('/app/components/landing/LandingPlaceholder.vue').default,AppCollapse: require('/app/components/app/AppCollapse.vue').default})
