
import Vue from "vue"
import stands from "@/const/landing-stands.json"

export default Vue.extend({
  props: {
    noHeading: { type: Boolean, default: false },
  },

  data: () => ({
    stands,
    isGridCollapsed: true,
  }),
})
