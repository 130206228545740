
import Vue from "vue"
import type { FaqItem } from "@/types/api/faq"

export default Vue.extend({
  data: () => ({
    items: [] as FaqItem[],
  }),

  async fetch () {
    await this.fetchItems()
  },

  computed: {
    collapseItems () {
      return this.items.filter(i => i.question && i.answer).map(i => ({
        title: i.question,
        content: this.$fmt.md(i.answer),
      }))
    },
  },

  methods: {
    async fetchItems () {
      const [res, err] = await this.$api.faq.getFaq()
      if (err) return console.error(err)
      this.items = res
    },
  },
})
