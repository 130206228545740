
import Vue from "vue"
import { nanoid } from "nanoid"
import breakpoints from "@/assets/style/_breakpoints.scss"
import type { Person } from "@/types/api/person"

export default Vue.extend({
  data: () => ({
    speakers: [] as Person[],
    navIds: [] as string[],
  }),

  async fetch () {
    await this.fetchSpeakers()
  },

  computed: {
    swiperOpts () {
      const { navIds } = this
      return {
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        grabCursor: true,
        navigation: {
          prevEl: `#${navIds[0]}`,
          nextEl: `#${navIds[1]}`,
          disabledClass: "swiper-disabled",
        },
        breakpoints: {
          [breakpoints.xs]: { slidesPerView: 2 },
          [breakpoints.md]: {
            // allowTouchMove: false,
            slidesPerView: 3,
            spaceBetween: 80,
          },
        },
      }
    },
  },

  mounted () {
    this.navIds = [...Array(2)].map(() => nanoid())
  },

  methods: {
    async fetchSpeakers () {
      const [res, err] = await this.$api.person.getSpeakers({
        params: {
          "filter[front]": true,
          "sort[front]": "asc",
        },
      })
      if (err) return console.error(err)
      this.speakers = this.$api.helpers.getList(res)
    },
  },
})
