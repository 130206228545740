import { render, staticRenderFns } from "./LandingNews.vue?vue&type=template&id=55b1d32c&scoped=true&"
import script from "./LandingNews.vue?vue&type=script&lang=ts&"
export * from "./LandingNews.vue?vue&type=script&lang=ts&"
import style0 from "./LandingNews.vue?vue&type=style&index=0&id=55b1d32c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b1d32c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingPlaceholder: require('/app/components/landing/LandingPlaceholder.vue').default})
