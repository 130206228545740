
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  props: {
    code: { type: String, required: true, validator: code => Boolean(code) },
    isBreak: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("user", ["user"]),

    telemetry (): { login: string, registration: string } {
      const { user } = this
      return {
        login: user?.id || "anonymous",
        registration: JSON.stringify({
          email: user?.email || "anonymous@eventico.ru",
        }),
      }
    },

    isBreak_: {
      get (): boolean { return this.isBreak },
      set (isBreak: boolean) { this.$emit("update:isBreak", isBreak) },
    },
  },

  mounted () {
    this.createPlayer()
    window.addEventListener("message", this.handlePlayerMessage)
  },
  beforeDestroy () {
    window.removeEventListener("message", this.handlePlayerMessage)
  },

  methods: {
    createPlayer () {
      const { player } = this.$refs
      if (!player) return
      window.comdiLoader?.createPlayer(player as HTMLElement)
    },

    async handlePlayerMessage (event: any) {
      let { data } = event
      if (!data) return
      try { data = JSON.parse(data) } catch { return }
      if (!data) return
      console.log(data)

      const { currentEpisode } = data
      if (currentEpisode !== undefined)
        if (currentEpisode === null) {
          this.isBreak_ = true
        } else {
          const code = currentEpisode.key
          if (code && code !== this.code) {
            const [res, err] = await this.$api.program.getSessionByCode(code)
            if (err) {
              console.error(err)
            } else if (res) {
              this.$emit("session-change", res)
              this.isBreak_ = false
            }
          }
        }

      const time = data.currentTime?.absoluteTime ?? 0
      if (time) this.isBreak_ = false
    },
  },
})
