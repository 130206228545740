
import Vue from "vue"
import groupBy from "lodash/groupBy"
import type { Track } from "@/types/api/program"

export default Vue.extend({
  data: () => ({
    tracks: [] as Track[],
  }),

  async fetch () {
    await this.fetchTracks()
  },

  computed: {
    // halls (): (Hall & { tracks: number })[] {
    //   const { tracks } = this
    //   const halls = []
    //   for (const t of tracks) {
    //     const hallAlias = t.hall.alias
    //     if (halls.findIndex(h => h.alias === hallAlias) < 0) halls.push({
    //       ...t.hall,
    //       tracks: tracks.reduce((acc: number, tt: Track) =>
    //         acc + (tt.hall.alias === hallAlias ? 1 : 0), 0),
    //     })
    //   }
    //   return halls
    // },

    tracksByGroup () {
      return groupBy(this.tracks, t => t.group.alias)
    },
  },

  methods: {
    async fetchTracks () {
      const [res, err] = await this.$api.program.getTracks()
      if (err) return console.error(err)
      this.tracks = this.$api.helpers.getList(res)
    },

    // getUrl (track: Track) {
    //   return this.localeRoute({
    //     path: "/program",
    //     query: {
    //       day: track.day.alias,
    //       group: track.group.alias,
    //       hall: track.hall.alias,
    //     },
    //   })
    // },
  },
})
