
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import type { MetaInfo } from "vue-meta"
import type { Session } from "@/types/api/program"

export default Vue.extend({
  data: () => ({
    liveSession: null as Nullable<Session>,
    isEmbedScriptLoaded: false,
    isBreak: false,
  }),

  async fetch () {
    await this.FETCH_HALLS()
    await this.fetchLiveSession()
  },

  head (): MetaInfo {
    return {
      script: [{
        src: this.$config.streamEmbedScript,
        defer: true,
        vmid: "embed-script",
        callback: () => { this.isEmbedScriptLoaded = true },
      }],
    }
  },

  computed: {
    ...mapState("live", ["halls"]),

    mainHall (): string {
      return this.halls[0]?.alias || ""
    },

    embedCode (): string {
      // return "rr6le53s"
      // return this.liveSession?.liveData.embedCode || ""
      return this.liveSession?.track.liveData.embedCode || ""
    },
  },

  methods: {
    ...mapActions("live", ["FETCH_HALLS"]),

    async fetchLiveSession () {
      const { mainHall } = this
      if (!mainHall) return
      const [res, err] =
        await this.$api.program.getHallLiveSession(mainHall)
      if (err) return console.error(err)
      this.liveSession = res
    },
  },
})
