
import Vue from "vue"
import pick from "lodash/pick"
import type { Partner } from "@/types/api/partner"

type SimplePartner = Pick<Partner, "title" | "logo" | "siteUrl">
interface ExtendedPartner extends Omit<SimplePartner, "logo" | "siteUrl"> {
  logo: string
  siteUrl: string
}

export default Vue.extend({
  data: () => ({
    partners: [] as SimplePartner[],
  }),

  async fetch () {
    await this.fetchPartners()
  },

  computed: {
    extendedPartners (): ExtendedPartner[] {
      return this.partners.map(p => ({
        ...p,
        siteUrl: this.$fmt.localize(p.siteUrl),
        logo: p.logo?.[this.$i18n.locale]?.url || "",
      }))
    },
  },

  methods: {
    async fetchPartners () {
      const [res, err] = await this.$api.partner.getPartners({
        // params: { "filter[front]": true, "sort[list]": "asc" },
      })
      if (err) return console.error(err)
      this.partners = this.$api.helpers.getList(res)
        .map((p: Partner) => pick(p, ["title", "logo", "siteUrl"]))
    },
  },
})
