
import Vue from "vue"
import type { NewsItem } from "@/types/api/news"

export default Vue.extend({
  data: () => ({
    news: [] as NewsItem[],
  }),

  async fetch () {
    await this.fetchNews()
  },

  methods: {
    async fetchNews () {
      const [res, err] = await this.$api.news.getNews({
        params: { "paginator[limit]": 4 },
      })
      if (err) return console.error(err)
      this.news = this.$api.helpers.getList(res)
    },
  },
})
