import { render, staticRenderFns } from "./LandingSpeakers.vue?vue&type=template&id=7f01e806&scoped=true&"
import script from "./LandingSpeakers.vue?vue&type=script&lang=ts&"
export * from "./LandingSpeakers.vue?vue&type=script&lang=ts&"
import style0 from "./LandingSpeakers.vue?vue&type=style&index=0&id=7f01e806&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f01e806",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/app/components/app/AppIcon.vue').default,AppAvatar: require('/app/components/app/AppAvatar.vue').default,AppSpinner: require('/app/components/app/AppSpinner.vue').default})
