
import Vue from "vue"
import { isSessionLive } from "@/utils/sessions"
import type { Session } from "@/types/api/program"

export default Vue.extend({
  props: {
    session: {
      type: Object as VuePropType<Session>,
      required: true,
      validator: session => Boolean(session),
    },
    isBreak: { type: Boolean, default: false },
  },

  computed: {
    isLive (): boolean {
      return isSessionLive(this.session)
    },
  },
})
